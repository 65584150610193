import React from 'react';
import Footer from './Footer';
import Header from './Header';
import Contact from './Contact';
import { Helmet } from 'react-helmet';

const AppPage = (props) => {
    let metaData = {
        title: "Home | amoraitis",
        description: "Welcome to amoraitis, showcasing drone photography and software engineering projects.",
        keywords: "drone photography, software engineering, amoraitis, anastasios, moraitis"
    };

    switch (props.path){
        case "drone":
            metaData = {
                title: "Drone | amoraitis",
                description: "Showcasing drone photos and videos",
                keywords: "drone photography, drone, amoraitis, anastasios, moraitis"
            };
            break;
        case "about-me":
            metaData = {
                title: "About me | amoraitis",
                description: "Professional career",
                keywords: "software engineering, amoraitis, anastasios, moraitis"
            };
            break;
        default:
            break;
    }

    return (
        <div className="App">
             <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
                <meta name="keywords" content={metaData.keywords} />
            </Helmet>
            <Header path={props.path}/>
            {props.view}
            <Contact />
            <Footer />
        </div>
    );
}

export default AppPage;