import React from 'react';
import { Grid, Header, Icon, Label, Segment } from 'semantic-ui-react';
import About from '../common/About';
import AppPage from '../common/AppPage';

const HomePage = () => {
  return <AppPage path='home' view={(
    <Segment placeholder>
      <Grid columns={2} stackable textAlign='center'>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column>
            <About />
          </Grid.Column>

          <Grid.Column>
            <Header icon>
              <Icon name='group' />
              Social Media
            </Header>
            <Segment>
              <a style={{margin: '1em'}} href="https://www.linkedin.com/in/anamoraitis/" target="_blank" rel="noreferrer"><img src="https://img.shields.io/badge/LinkedIn-%230077B5.svg?&style=social&logo=linkedin&logoSize=auto" alt="LinkedIn" /></a>
              <a style={{margin: '1em'}} href="https://www.instagram.com/amoraitis_/" target="_blank" rel="noreferrer"><img src="https://img.shields.io/badge/Instagram-%23E4405F.svg?&style=social&logo=instagram&logoSize=auto" alt="Instagram" /></a>
              <a style={{margin: '1em'}} href="https://github.com/amoraitis" target="_blank" rel="noreferrer" ><img alt="followers" title="Follow me on Github" src="https://img.shields.io/github/followers/madushadhanushka?color=236ad3&style=social&logo=github&label=Follow&logoSize=auto" /></a>
              <a style={{margin: '1em'}} href="https://www.youtube.com/channel/UCnxF6uBHsjzFPfwT6C6ZWeg" target="_blank" rel="noreferrer" ><img alt="YouTube Channel Subscribers" src="https://img.shields.io/youtube/channel/subscribers/UCnxF6uBHsjzFPfwT6C6ZWeg?&style=social&logo=youtube&label=Subscribe&logoSize=auto" /></a>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column>
            <Header>
              Github Overview
            </Header>
            <img src="https://myreadme.vercel.app/api/embed/amoraitis?panels=userstatistics,toplanguages,commitgraph" alt="reimaginedreadme" />
          </Grid.Column>

          <Grid.Column>
            <Header icon>
              <Icon name='thumbs up' />
              I like
            </Header>
            <Segment>
              <Label.Group size='large'>
                <Label icon="coffee" content="Coffee" />
                <Label icon="headphones" content="Music" />
                <Label icon="plane" content="Traveling" />
                <Label icon="code" content="Coding" />
                <Label icon="food" content="Good Food" />
              </Label.Group>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )} />;
}

export default HomePage;
