import React from 'react';
import { Container, Segment } from 'semantic-ui-react';

const Footer = () => {
  return (
    <Segment inverted vertical style={{ padding: '2em 0em' }}>
      <Container textAlign='center'>
        &copy; {new Date().getFullYear()} amoraitis. All rights reserved.
      </Container>
    </Segment>
  );
}

export default Footer;
