import React from 'react';
import { Segment, Container, Header, List } from 'semantic-ui-react';

const Experience = () => {
  return (
    <Segment style={{ padding: '4em 0em' }} vertical>
      <Container text>
        <Header as='h3'>Professional Experience</Header>
        <List>
          <List.Item>
            <Header as='h4'>RCS Tech. Greece | 09/2019 - Now</Header>
            <p>Maintainer/Developer of the client-facing app and various internal tools @ Media Monitors Development Team.</p>
          </List.Item>
          {/* Add more experiences as needed */}
        </List>
      </Container>
    </Segment>
  );
}

export default Experience;