import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import AboutMe from './about-me/AboutMe';
import './App.css';
import HomePage from './home/HomePage';
import Drone from './drone/Drone';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/about-me" element={<AboutMe />} />
        <Route exact path="/drone" element={<Drone />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
