import React from 'react';
import { Segment, Container, Header, Label } from 'semantic-ui-react';

const Skills = () => {
  return (
    <Segment style={{ padding: '4em 0em' }} vertical>
      <Container text>
        <Header as='h3'>Skills</Header>
        <Label.Group size='large'>
          <Label>.NET</Label>
          <Label>JavaScript</Label>
          <Label>React</Label>
          <Label>Python</Label>
          <Label>SQL</Label>
          {/* Add more skills as labels */}
        </Label.Group>
      </Container>
    </Segment>
  );
}

export default Skills;
