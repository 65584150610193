import React from 'react';
import { Segment, Container, Header, List, Icon, Button } from 'semantic-ui-react';

const Contact = () => {
  return (
    <Segment style={{ padding: '4em 0em', background: '#1B1C1D' }} vertical inverted>
      <Container text>
        <Header as='h3' inverted textAlign='center'>Get in Touch</Header>
        <List horizontal style={{ justifyContent: 'center' }}>
          <List.Item>
            <Button size="large" as="a" href="mailto:anas.moraitis@gmail.com" target="_blank" rel="noreferrer">
              <Icon name='mail' /> Email
            </Button>
          </List.Item>
          <List.Item>
            <Button color='linkedin' size='large' as="a" href="https://www.linkedin.com/in/anamoraitis" target="_blank" rel="noreferrer">
              <Icon name='linkedin' /> LinkedIn
            </Button>
          </List.Item>
          <List.Item>
            <Button color='grey' size='large' as="a" href="https://github.com/amoraitis" target="_blank" rel="noreferrer">
              <Icon name='github' /> GitHub
            </Button>
          </List.Item>
          <List.Item>
            <Button color='instagram' size='large' as="a" href="https://www.instagram.com/amoraitis_/" target="_blank" rel="noreferrer">
              <Icon name='instagram' /> Instagram
            </Button>
          </List.Item>
          {/* Add more icons and links as needed */}
        </List>
      </Container>
    </Segment>
  );
}

export default Contact;
