import React from 'react';
import { Segment } from 'semantic-ui-react';
import Video from './Video';

const Videos = () => {
    let videosUrl = ['C65ejn1sf49', 'C6hMFSUs4zW', 'C5MGJfBs4Ey', 'C4RIWOhMvx_', 'C2dL6y-MIdD', 'CnZVVWvARyV', 'Cm1r2Bds2FN', 'ChEc_VlAu19'];
    let videos = videosUrl.map((video, i) => {return <Video id={video} key={i}></Video>})

  return (
    <Segment style={{ padding: '4em 4em' }} vertical>
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center' }}>
            {videos}
        </div>
    </Segment>
  );
}

export default Videos;