import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { FaHelicopter, FaHouse, FaInfo } from 'react-icons/fa6';

const Header = (props) => {
  const [activeItem, setActiveItem] = useState(props != null ? props.path : 'home');

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };

  return (
    <div >
      <Menu stackable icon='labeled'>
        <Menu.Item header>Anastasios Moraitis</Menu.Item>

        <Menu.Menu position='right'>
          {/* Desktop Menu Items */}
          <Menu.Item
            name='home'
            as={Link}
            to="/"
            active={activeItem === 'home'}
            onClick={handleItemClick}>
            <FaHouse size="1.5em"/>
            Home
          </Menu.Item>
          <Menu.Item
            name='about-me'
            as={Link}
            to="/about-me"
            active={activeItem === 'about-me'}
            onClick={handleItemClick}>
            <FaInfo size="1.5em"/>
            About me
          </Menu.Item>
          <Menu.Item
            name='drone'
            as={Link}
            to="/drone"
            active={activeItem === 'drone'}
            onClick={handleItemClick}>
            <FaHelicopter size="1.5em"/>
            Drone
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </div>

  );
};

export default Header;
