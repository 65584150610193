import React from 'react';
import { Link } from 'react-router-dom';
import { Segment, Container, Header, Card, Icon } from 'semantic-ui-react';

const Projects = () => {
  return (
    <Segment style={{ padding: '4em 0em' }} vertical>
      <Container text textAlign='center'>
        <Header as='h3'>Projects</Header>
        <Card.Group centered>
        <Card>
            <Card.Content>
              <Card.Header>EclassMobileApi</Card.Header>
              <Card.Description>
                A library for retrieving user and other info from an Eclass instance. 
                <img src="https://img.shields.io/github/stars/amoraitis/EclassMobileApi?style=social" alt="GitHub stars"/>
              </Card.Description>
              <Link href="blank:https://github.com/amoraitis/EclassMobileApi">Source Code on Github</Link>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Card.Header>TodoList</Card.Header>
              <Card.Description>
                A simple todo-list app built to learn. 
                <img src="https://img.shields.io/github/stars/amoraitis/TodoList?style=social" alt="GitHub stars"/>
              </Card.Description>
              <Link href="blank:https://github.com/amoraitis/TodoList">Source Code on Github</Link>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Card.Header>mscba</Card.Header>
              <Card.Description>
                A subset of projects completed during my MSc in Business Analytics. 
                <img src="https://img.shields.io/github/stars/amoraitis/mscba?style=social" alt="GitHub stars"/>
              </Card.Description>
              <Link href="blank:https://github.com/amoraitis/mscba">Source Code on Github</Link>
            </Card.Content>
          </Card>
          <Card>
          <Card.Content>
            <Card.Header><Icon name="code"/></Card.Header>
            <Card.Description />
              <Link href="blank:https://github.com/amoraitis?tab=repositories">And many more...</Link>
            </Card.Content>
          </Card>
          
          {/* Repeat for more projects */}
        </Card.Group>
      </Container>
    </Segment>
  );
}

export default Projects;
