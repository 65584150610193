import React from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { FaHelicopter } from "react-icons/fa6";

const AboutDrone = (props) => {
  return (
    <Segment id="about" style={{ padding: '4em 0em' }} vertical="true">
      <Container vertical="true">
      <FaHelicopter size="4em"/>
        <p style={{fontSize: '1.5em'}}>
          {props.message}
        </p>
      </Container>
      <a style={{margin: '1em'}} href="https://www.youtube.com/channel/UCnxF6uBHsjzFPfwT6C6ZWeg" target="_blank" rel="noreferrer" ><img alt="YouTube Channel Subscribers" src="https://img.shields.io/youtube/channel/subscribers/UCnxF6uBHsjzFPfwT6C6ZWeg?&style=social&logo=youtube&label=Subscribe&logoSize=auto" /></a>
      <a style={{margin: '1em'}} href="https://www.instagram.com/amoraitis_/" target="_blank" rel="noreferrer"><img src="https://img.shields.io/badge/Instagram-%23E4405F.svg?&style=social&logo=instagram&logoSize=auto" alt="Instagram" /></a>
      <a style={{margin: '1em'}} href="https://www.youtube.com/channel/UCnxF6uBHsjzFPfwT6C6ZWeg" target="_blank" rel="noreferrer" ><img alt="YouTube Channel Views" src="https://img.shields.io/youtube/channel/views/UCnxF6uBHsjzFPfwT6C6ZWeg?&style=social&logo=youtube&label=Views&logoSize=auto" /></a>
    </Segment>
  );
}

export default AboutDrone;