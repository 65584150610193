import AboutDrone from './AboutDrone';
import Videos from './Videos';
import AppPage from '../common/AppPage';

import React, { useEffect } from 'react'; // Import the useEffect hook from the react package
import { Segment } from 'semantic-ui-react';

const Drone = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('data-name', 'BMC-Widget');
    script.setAttribute('data-cfasync', 'false');
    script.setAttribute('src', 'https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js');
    script.setAttribute('data-id', 'amoraitis_');
    script.setAttribute('data-description', 'Support me on Buy me a coffee!');
    script.setAttribute('data-message', '');
    script.setAttribute('data-color', '#5F7FFF');
    script.setAttribute('data-position', 'Right');
    script.setAttribute('data-x_margin', '18');
    script.setAttribute('data-y_margin', '18');
  
    document.head.appendChild(script);

    // Cleanup the script when component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []); 
  
  return <AppPage path='drone' view={(
    <Segment style={{ padding: '4em 0em' }} vertical>
      <AboutDrone message="Enjoy a subset of photos and videos captured using my drone."/>
      <Videos />
    </Segment>
  )} />;
}

export default Drone;