import React from 'react';
import { InstagramEmbed } from 'react-social-media-embed';

const Video = (props) => {
  return props != null && props.id != null? (
    <div className='drone-video' key={props.key} style={{ flex: '1 0 21%', margin: '0 1em' }}>
    <InstagramEmbed  url={"https://www.instagram.com/p/" + props.id} width={'fluid'} captioned />
    </div>
  ) : (<span />);
}

export default Video;