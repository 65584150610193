import profile from '../profile.png';

import React from 'react';
import { Segment, Container, Image } from 'semantic-ui-react';

const About = () => {
  return (
    <Segment id="about" style={{ padding: '4em 0em' }} vertical>
      <Container vertical>
        <Image
          centered
          size='medium'
          src={profile}
        />
        <p>
          Hello! I'm Anastasios Moraitis, a passionate Software Engineer and Business Analytics Enthusiast.
        </p>
      </Container>
    </Segment>
  );
}

export default About;