import About from '../common/About';
import Education from '../about-me/Education';
import Experience from '../about-me/Experience';
import Projects from '../about-me/Projects';
import Skills from '../about-me/Skills';
import AppPage from '../common/AppPage';

import React from 'react';
import { Segment } from 'semantic-ui-react';

const AboutMe = () => {
  return <AppPage path='about-me' view={(
    <Segment style={{ padding: '4em 0em' }} vertical>
      <About />
      <Experience />
      <Education />
      <Skills />
      <Projects />
    </Segment>
  )} />;
}

export default AboutMe;