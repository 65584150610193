import React from 'react';
import { Segment, Container, Header, List } from 'semantic-ui-react';

const Education = () => {
  return (
    <Segment style={{ padding: '4em 0em' }} vertical>
      <Container text>
        <Header as='h3'>Education</Header>
        <List>
          <List.Item>
            <Header as='h4'>Bachelor of Science in Computer Science</Header>
            <p>Department of Informatics | Athens University of Economics and Business, 2021</p>
            {/* Add details about relevant courses or projects if you like */}
          </List.Item>
          <List.Item>
            <Header as='h4'>Master of Science in Business Analytics</Header>
            <p>Department Of Management Science And Technology | Athens University of Economics and Business, 2023</p>
            {/* Include any specializations or notable projects */}
          </List.Item>
          {/* Add other degrees or certifications if applicable */}
        </List>
      </Container>
    </Segment>
  );
}

export default Education;